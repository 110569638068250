import React, { useEffect, useState, useCallback } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import BusyLoaderSimple from '../../../components/loading-spinner/busy-loader-simple';
import PropTypes from 'prop-types';

function GetCourtPicture({ socket, deviceId }) {
  const [courtImageUrl, setCourtImageUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('No court picture available');

  const requestCourtPicture = useCallback(async () => {
    setCourtImageUrl('');
    setIsLoading(true);

    if (socket && socket.connected) {
      await socket.emit('request_court_image', { deviceId });
    } else {
      setIsLoading(false);
      setMessage('Socket not connected');
    }
  }, [socket, deviceId]);

  const receivedCourtImage = (data) => {
    console.log('received_court_image data:', data);
    const { status = '', file_name: fileName = '' } = data;
    if (status === 'success' && fileName) {
      setCourtImageUrl(fileName);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (socket) {
      socket.on('received_court_image', receivedCourtImage);

      return () => {
        socket.off('received_court_image', receivedCourtImage);
      };
    }
  }, [socket]);

  const handleRequestCourtPicture = useCallback(async () => {
    setCourtImageUrl('');
    await requestCourtPicture();
    // Assuming requestCourtPicture updates the courtImageUrl somehow
  }, [requestCourtPicture]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={10}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleRequestCourtPicture}
        >
          Request Court Picture
        </Button>
      </Grid>
      {
        isLoading && (
          <Grid item xs={10}>
            <BusyLoaderSimple/>
          </Grid>
        )
      }
      <Grid item xs={12}>
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', marginY: '2rem' }}>
          {
            !courtImageUrl ? (
                <Typography variant="h6" component="div" color="grey">
                  {message}
                </Typography>)
              : (
                <img
                  src={`https://ball-machine.waltair.io/static/court-images/${courtImageUrl}`}
                  width="80%"
                  alt="court"
                />
                )
          }
        </Box>
      </Grid>
    </Grid>
  );
}

GetCourtPicture.propTypes = {
  socket: PropTypes.object.isRequired,
  deviceId: PropTypes.string.isRequired,
};

export default React.memo(GetCourtPicture);
