import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { useDispatch, useSelector } from 'react-redux';
import lodash from 'lodash';
import {
  useParams,
} from 'react-router-dom';
import {
  GET_DEVICE_DETAILS,
  getDeviceDetailsSuccess,
  GET_SERVICE_STATUS,
} from '../devices.reducer';
import Link from '@mui/material/Link';
import { socketInstance } from '../../../socket';
import DeviceHealthInfo from './device-health-info';
import GetCourtPicture from './get-court-picture';
import ServicesStatus from './services-status';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`device-details-tab-panel-${index}`}
      aria-labelledby={`device-details-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const DeviceDetails = () => {
  const params = useParams();
  const deviceId = params.id || null;
  const dispatch = useDispatch();
  const socketRef = useRef(null);
  const [, setIsLoading] = useState(false);
  const [, setMessage] = useState('No court picture available');
  const [tabIndex, setTabIndex] = React.useState(0);
  const [servicesStatus, setServicesStatus] = useState({});
  const { serviceStatus: serviceData } = useSelector((state) => state.deviceInfo);
  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  const requestLauncherServiceRestart = useCallback(async (serviceName) => {
    console.log('requestLauncherServiceRestart serviceName:', serviceName);
    // eslint-disable-next-line react/prop-types
    if (socketRef.current && socketRef.current.connected) {
      // eslint-disable-next-line react/prop-types
      await socketRef.current.emit('restart_launcher_service', { deviceId, service: serviceName });
    } else {
      setIsLoading(false);
      setMessage('Socket not connected');
    }
  }, [
    deviceId
  ]);

  useEffect(() => {
    const authToken = localStorage.getItem('auth-token');
    const socket = socketInstance({
      authToken,
      deviceId,
    });

    function onConnect() {
      console.log(`connected to socket ID:: ${socket.id}, timestamp: ${new Date()}`);
      socketRef.current = socket;
    }

    function onDisconnect(reason) {
      console.log(`Socket connection status: ${socket.connected}, timestamp: ${new Date()}`);
      console.log('Socket connection lost. reason:', reason);
    }

    function connectError(err) {
      console.log(`connect_error due to ${err.message}`);
      console.log('Socket is active:', socket.active);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('connect_error', connectError);

    const deviceDetailsHandler = async (data) => {
      if (data && Object.keys(data).length > 0 && data.device_id === deviceId) {
        dispatch(getDeviceDetailsSuccess({ data: { deviceDetails: { ...data } } }));
      }
    };

    socket.on('device-details', deviceDetailsHandler);

    const servicesStatusHandler = async (data) => {
      console.log('received_services_status data:', data);
      setServicesStatus({ statuses: lodash.get(data, 'data', {}), lastReceived: new Date() });
    };

    socket.on('received_services_status', servicesStatusHandler);

    socket.connect();

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('connect_error', connectError);
      socket.off('device-details', deviceDetailsHandler);
      socket.off('received_services_status', deviceDetailsHandler);
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!deviceId) {
      return;
    }
    dispatch({
      type: GET_DEVICE_DETAILS,
      payload: {
        deviceId
      },
    });

    dispatch({
      type: GET_SERVICE_STATUS,
      payload: {
        deviceId
      },
    });
  }, [deviceId, dispatch]);

  /* useEffect(() => {
    socket.on('device-details', handleDeviceDetails);

    return () => {
      socket.off('device-details', handleDeviceDetails);
    };
  }, [deviceId, socket, handleDeviceDetails]); */

  return (<Stack direction="column" spacing={2} mb="2rem">
    <Box
      sx={{
        textAlign: 'center', marginBottom: '2rem'
      }}
    >
      <h1>Device Details</h1>
    </Box>
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="flex-end"
      spacing={2}
    >
      <Link href="/device-list" underline="hover">
        {'Back to Device List'}
      </Link>
    </Stack>
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
      <Tabs value={tabIndex} onChange={handleChangeTab} aria-label="device_details_tabs" indicatorColor="primary"
            textColor="inherit"
            variant="fullWidth">
        <Tab label="Device Health Info"/>
        <Tab label="Device Services' Status"/>
        <Tab label="Get Court Picture"/>
      </Tabs>
    </Box>
    <TabPanel value={tabIndex} index={0}>
      <DeviceHealthInfo/>
    </TabPanel>
    <TabPanel value={tabIndex} index={1}>
      <ServicesStatus
        data={Object.keys(servicesStatus).length > 0 ? servicesStatus : serviceData}
        handleLauncherServiceRestart={requestLauncherServiceRestart}
      />
    </TabPanel>
    <TabPanel value={tabIndex} index={2}>
      <GetCourtPicture
        socket={socketRef.current}
        deviceId={deviceId}
      />
    </TabPanel>
  </Stack>);
};

export default DeviceDetails;
